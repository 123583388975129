import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadOfferRequest, uploadOfferSuccess, uploadOfferFailure } from "../../thunks";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BACKEND_DOMAIN from "../../../../service";
import axios from "axios";

const MerchantProductOfferAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [userData, setUserData] = useState({});
    const [businessname, setBusinessName]=useState('');
    const [businessId, setBusinessId] = useState('');

    const [offer, setOffer] = useState({
        name: "",
        offer_code: "",
        cash_value: 0,
        percentage_value: 0,
        description: "",
        offer_value_type: "",
        products: [],
        start_datetime: "",
        end_datetime: "",
        item_price: "",
        exclusive:"",
        activate:"",
        max_global_applicant:0,
        max_user_applicant:0,
        num_orders:0,
        num_applicant:0,
        total_discount:0,
        max_discount:0
    });

    const [productsList, setProductsList] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [discountType, setDiscountType] = useState(0);
    const [cashValue, setCashValue] = useState(0);
    const [percentageValue, setPercentageValue] = useState("");
   

    const token = useSelector((state) => state.Login.token);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchUserData();
        if (token) {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/products/list`;
            fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => setProductsList(data.results))
                .catch((error) => console.error("Error fetching products:", error));
        }
    }, [token]);


    const fetchUserData = async () => {
        try {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/detail/`;
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUserData(response.data);
            setBusinessId(response.data.business);

         //   console.log('business info ',response.data);
        } catch (error) {
            console.error("Error fetching user details:", error.message);
        }
    };

    console.log('this business ',userData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "offer_value_type") {
            setDiscountType(value);
            // Reset values for percentageValue and cashValue
            setPercentageValue(value === "PERCENTAGE" ? 0 : "");
            setCashValue(value === "CASH" ? 0 : "");
        } else if (name === "cash_value") {
            setCashValue(value);
        } else if (name === "percentage_value") {
            setPercentageValue(value);
        }

        setOffer({
            ...offer,
            [name]: value,
        });
    };

    const handleMultiSelectChange = (products) => {
        const selectedProduct = products.length > 0 ? products[0] : null;

        setOffer({
            ...offer,
            products,
            item_price: selectedProduct ? selectedProduct.price : "",
        });
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            dispatch(uploadOfferRequest());

            const formData = new FormData();
            formData.append("name", offer.name);
            formData.append("offer_code", offer.offer_code);
            formData.append("owner", businessId); 
            formData.append("cash_value", cashValue);
            formData.append("percentage_value", percentageValue);
            formData.append("offer_value_type", offer.offer_value_type);
            formData.append("description", offer.description);
            formData.append("start_datetime", startDate.toISOString().slice(0, -5));
            formData.append("end_datetime", endDate.toISOString().slice(0, -5));
            formData.append("item_price", offer.item_price);
            // added parameters
            formData.append("exclusive", offer.exclusive);
            formData.append("activate", offer.activate);
            formData.append("max_global_applicant", offer.max_global_applicant);
            formData.append("max_user_applicant", offer.max_user_applicant);
            formData.append("num_orders", offer.num_orders);
            formData.append("num_applicant", offer.num_applicant);
            formData.append("total_discount", offer.total_discount);
            formData.append("max_discount", offer.max_discount);


            const productIds = offer.products.map((product) => product.value);
            formData.append("products", JSON.stringify(productIds));

              for (let[key, value] of formData.entries()){
                 console.log(key,':', value);
              }
            const apiurl = `${BACKEND_DOMAIN}/business/offer/create`;
            const response = await fetch(apiurl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error adding product");
            }

            const data = await response.json();
            dispatch(uploadOfferSuccess(data));

            // Show a success message:
            setSuccessMessage("Offer has been created successfully!");

            setOffer({
                name: "",
                offer_code: "",
                cash_value: 0,
                percentage_value: 0,
                description: "",
                offer_value_type: "",
                products: [],
                start_datetime: "",
                end_datetime: "",
                item_price: "",
                exclusive:"",
                activate:"",
                max_global_applicant:0,
                max_user_applicant:0,
                num_orders:0,
                num_applicant:0,
                total_discount:0,
                max_discount:0
            });

            setTimeout(() => {
                navigate("/route-money/merchant/product-offers");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(uploadOfferFailure(error.message));
        }

        setIsLoading(false);

    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Create Offer"
                        breadcrumbItems={[
                            { title: "Offers", link: "/offers" },
                            { title: "Create Offer", link: "#" },
                        ]}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Create Offer</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerName">
                                                        Offer Name
                                                    </Label>
                                                    <AvField
                                                        name="name"
                                                        id="offerName"
                                                        type="text"
                                                        placeholder="Enter Offer Name"
                                                        errorMessage="Enter Offer Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerCode">
                                                        Offer Code
                                                    </Label>
                                                    <AvField
                                                        name="offer_code"
                                                        id="offerCode"
                                                        type="text"
                                                        placeholder="Enter Offer Code"
                                                        errorMessage="Enter Offer Code"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="discountType">
                                                        Discount Type
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="offer_value_type"
                                                        id="discountType"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option value="">Select Discount Type</option>
                                                        <option value="CASH">Cash</option>
                                                        <option value="PERCENTAGE">Percentage</option>
                                                    </AvField>
                                                </div>
                                            </Col>
                                            {discountType === "CASH" && (
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="cashValue">
                                                            Cash Value
                                                        </Label>
                                                        <AvField
                                                            name="cash_value"
                                                            id="cashValue"
                                                            type="number"
                                                            placeholder="Enter Cash Value"
                                                            errorMessage="Enter Cash Value"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Col>
                                            )}

                                            {discountType === "PERCENTAGE" && (
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="percentageValue">
                                                            Percentage Value
                                                        </Label>
                                                        <AvField
                                                            name="percentage_value"
                                                            id="percentageValue"
                                                            type="number"
                                                            placeholder="Enter Percentage Value"
                                                            errorMessage="Enter Percentage Value"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Col>
                                            )}
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="offerDescription">
                                                        Description
                                                    </Label>
                                                    <AvField
                                                        name="description"
                                                        id="offerDescription"
                                                        type="text"
                                                        placeholder="Enter Offer Description"
                                                        errorMessage="Enter Offer Description"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="products">
                                                        Select Products
                                                    </Label>
                                                    <Select
                                                        value={offer.products}
                                                        isMulti
                                                        onChange={(selectedOptions) =>
                                                            handleMultiSelectChange(selectedOptions)
                                                        }
                                                        options={productsList.map((product) => ({
                                                            value: product.id,
                                                            label: product.name,
                                                            price: product.price,
                                                        }))}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                        <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="discountType">
                                                     Exclusive
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="exclusive"
                                                        id="discountType"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option value="">Select Option</option>
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </AvField>
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="discountType">
                                                      Activate
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        name="activate"
                                                        id="activate"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option value="">Select Option</option>
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </AvField>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="cashValue">
                                                           Maximum Global Applicant
                                                        </Label>
                                                        <AvField
                                                            name="max_global_applicant"
                                                            id="maxGlobalApplicant"
                                                            type="number"
                                                            placeholder="Enter Maximum Global Applicant"
                                                            errorMessage="Enter Maximum Global Applicant"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="cashValue">
                                                            Maximum user applicants
                                                        </Label>
                                                        <AvField
                                                            name="max_user_applicant"
                                                            id="maxUserApplicant"
                                                            type="number"
                                                            placeholder="Enter  maximum user applicants"
                                                            errorMessage="Enter  maximum user applicants"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Col>

                                        </Row>

                                        <Row>
                                        <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="cashValue">
                                                            Number of Orders
                                                        </Label>
                                                        <AvField
                                                            name="num_orders"
                                                            id="numOrders"
                                                            type="number"
                                                            placeholder="Enter Number of Orders"
                                                            errorMessage="Enter Number of Orders"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="cashValue">
                                                            Number of Applicants
                                                        </Label>
                                                        <AvField
                                                            name="num_applicant"
                                                            id="numApplicant"
                                                            type="number"
                                                            placeholder="Enter Number of Applicants"
                                                            errorMessage="Enter Number of Applicants"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Col>


                                        </Row>

                                        <Row>
                                        <Col md="6">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="cashValue">
                                                            Total Discount
                                                        </Label>
                                                        <AvField
                                                            name="total_discount"
                                                            id="totalDiscount"
                                                            type="number"
                                                            placeholder="Enter Total Discount"
                                                            errorMessage="Enter Total Discount"
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="discountType">
                                                        Maximum Discount
                                                    </Label>
                                                    <AvField
                                                        name="max_discount"
                                                        id="maxDiscount"
                                                        type="number"
                                                        placeholder="Enter Maximum Discount"
                                                        errorMessage="Enter Maximum Discount"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

    
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="startDate">
                                                        Start Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleStartDateChange}
                                                        className="form-control"
                                                        id="startDate"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="endDate">
                                                        End Date
                                                    </Label>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={handleEndDateChange}
                                                        className="form-control"
                                                        id="endDate"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>


                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Creating..</span>
                                                </>
                                            ) : (
                                                "Create Offer"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantProductOfferAdd;
